import React from "react";
import CareersComponent from "../components/careers";

const Careers = () => {
  return (
    <div>
      <CareersComponent />
    </div>
  );
};

export default Careers;
