import React, { useState } from "react";

const OfferCard = ({ icon, title, subtitle }) => {
  const [isCardHovered, setIsCardHovered] = useState(false);
  return (
    <div
      className="w-[90%] md:w-[70%] lg:w-[30%] h-[350px] relative rounded-md p-4 text-white transition-colors duration-200 bg-headerBg cursor-pointer"
      style={{
        backgroundImage:
          "linear-gradient(to left, transparent, transparent 50%, #BB0101 50%, #BB0101)",
        backgroundPosition: "100% 0",
        backgroundSize: "200% 100%",
        transition: "background-position 0.25s ease-in",
        color: "white", // Initial text color
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundPosition = "0 0";
        e.currentTarget.style.color = "#333";
        setIsCardHovered(true);
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundPosition = "100% 0";
        e.currentTarget.style.color = "white";
        setIsCardHovered(false);
      }}
    >
      <div className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-white mb-5">
        {icon}
      </div>
      <h1
        className={`text-lg lg:text-xl xl:text-2xl ${
          isCardHovered ? "text-white" : "text-black"
        } font-bold`}
      >
        {title}{" "}
      </h1>
      <p
        className={`text-sm  ${
          isCardHovered ? "text-white" : "text-gray-400"
        } font-semibold mt-4`}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default OfferCard;
