import { Suspense } from "react";
import { Outlet, useLocation, useRoutes } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Home from "../pages/home";
import Careers from "../pages/careers";
import Ksict from "../pages/ksict";

export const Router = () => {
  const location = useLocation();
  const routes = useRoutes([
    {
      element: (
        <Suspense>
          <Navbar />
          <Outlet />
          {!location.pathname.includes("vacancies") &&
            !location.pathname.includes("kosovo-ict-learn-more") && <Footer />}
        </Suspense>
      ),
      children: [
        {
          element: <Home />,
          index: true,
        },
        {
          path: "/vacancies",
          element: <Careers />,
        },
        {
          path: "/kosovo-ict-learn-more",
          element: <Ksict />,
        },
      ],
    },
  ]);

  return routes;
};
