import React from "react";

const AboutCard = ({ icon, title, subtitle, dataAos }) => {
  return (
    <div
      className="w-[90%] lg:w-[350px] p-5 group cursor-pointer"
      data-aos={dataAos}
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <div className="w-[45px] h-[45px] rounded-full flex items-center justify-center bg-gray-200 group-hover:bg-primary transition-colors duration-300 text-black group-hover:text-white">
        {icon}
      </div>
      <h1 className="text-xl lg:text-2xl my-2 font-bold">{title}</h1>
      <p className="text-gray-400 font-semibold">{subtitle}</p>
    </div>
  );
};

export default AboutCard;
