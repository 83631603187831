import React from "react";
import { Link as ScrollLink } from "react-scroll";
import homeBanner from "../../assets/banner.webp";
import PrimaryButton from "../primary-button";

const HomeSection = () => {
  return (
    <div
      id="home"
      className="h-[85vh] lg:h-[65vh] w-auto bg-no-repeat"
      style={{
        backgroundImage: `url(${homeBanner})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full md:w-[95%] lg:w-[75%] items-center m-auto flex md:items-start flex-col justify-center h-full">
        <h1 className="text-xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-bold text-center md:text-left w-full md:w-[800px] tracking-wide leading-tight">
          YOUR PARTNER IN IT
        </h1>
        <h2 className="text-lg text-white font-bold text-center md:text-left w-full md:w-[800px] tracking-wide leading-tight pt-5">
          Connecting Expertise, Empowering Growth
        </h2>
        <p className="text-lg text-gray-300 my-8 w-full md:w-[700px] text-center md:text-left tracking-wide leading-relaxed">
          BOTA connects Dutch businesses with top-tier IT professionals from
          Kosovo, enabling your organization to achieve faster, more flexible,
          and efficient growth.
        </p>
        <ScrollLink to="services" smooth={true} duration={500} offset={-70}>
          <PrimaryButton text={"Discover more"} />
        </ScrollLink>
      </div>
    </div>
  );
};

export default HomeSection;
