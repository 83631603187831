import React from "react";
import bgImage from "../../assets/our-story.webp";
import teamImage from "../../assets/team.webp";

const OurStory = () => {
  return (
    <section
      className="w-full h-auto xl:h-[65vh] my-8 pb-10"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
      }}
      id="story"
    >
      <div className="w-[85%] md:w-[75%] lg:w-[65%] xl:w-[65%] h-[100%] m-auto flex flex-col xl:flex-row items-center gap-10 lg:gap-20">
        <img
          alt="team"
          src={teamImage}
          className="h-[450px] md:h-[550px] lg:h-[450px] xl:h-[70%] object-contain pt-3 xl:pt-0"
          data-aos={"fade-right"}
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        />
        <div
          className="h-[85%]"
          data-aos={"fade-left"}
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <span className=" text-white rounded-md text-2xl font-bold">
            {" "}
            Our Story
          </span>
          <p className="text-gray-200 text-sm mt-5 font-semibold ">
            At BOTA, we believe that success is driven by the right talent at
            the right time. Our story began with a clear vision: to give
            companies in the Netherlands access to the best IT professionals,
            while at the same time bringing out the enormous potential of talent
            in Kosovo. We bring these two worlds together - and we do it faster
            and more effectively than anyone else.{" "}
          </p>
          <p className="text-gray-200 text-sm mt-5 font-semibold ">
            Our mission is simple but powerful: to help Dutch companies grow by
            providing immediate access to high-quality IT professionals. No
            wasting time on long recruitment processes or building the right
            team-we deliver experts who are there from day one. At the same
            time, we offer professionals in Kosovo the chance to shape their
            careers internationally, without leaving their home country. This is
            not outsourcing as you know it, this is building a future for
            companies and talent in two markets that are perfectly aligned.
          </p>
          <p className="text-gray-200 text-sm mt-5 font-semibold ">
            What sets us apart? Flexibility, speed and unparalleled quality. In
            a world where technology and the demand for talent are changing at
            lightning speed, we make sure your organization is always ahead of
            the curve. With BOTA, you choose a partner who is fully committed to
            achieving your goals-we deliver what you need, when you need it. No
            compromises. Only results.
          </p>
          {/* <br /> */}
          <p className="text-gray-200 text-sm mt-5 font-semibold  ">
            We don't just build bridges between countries; we build the future
            of your business. That's what drives us. That's BOTA.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
