import React from "react";

const PrimaryButton = ({ text, isInHeader, onClick }) => {
  return (
    <button
      className={`px-[20px] py-[10px] rounded-md bg-primary text-white font-semibold hover:bg-red-600 cursor-pointer ${
        isInHeader ? "hidden lg:block" : ""
      } `}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
