import React from "react";
import HomeSection from "../components/home-section";
import AboutSection from "../components/about-section";
import OurStory from "../components/our-story-section";
import BotaOffer from "../components/offer-section";
import Market from "../components/market";

const Home = () => {
  return (
    <>
      <HomeSection />
      <AboutSection />
      <OurStory />
      <BotaOffer />
      <Market />
    </>
  );
};

export default Home;
