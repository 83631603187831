import React from "react";
import AboutCard from "../components/about-section/about-card";
import { FaPeopleLine } from "react-icons/fa6";
import { GrTechnology } from "react-icons/gr";
import { SiGitconnected } from "react-icons/si";
import { MdOutlinePriceChange } from "react-icons/md";
import { RiTimeZoneFill } from "react-icons/ri";
import banner from "../assets/kosovo-ict.webp";
import parallax from "../assets/parallax-image.webp";

const Ksict = () => {
  return (
    <div>
      <div
        id="home"
        className="h-[85vh] w-auto bg-no-repeat"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full md:w-[70%] items-center m-auto flex md:items-start flex-col justify-center h-full">
          <h1 className="text-xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-bold text-center md:text-left w-full md:w-[800px] tracking-wide leading-tight">
            Why work with IT professionals from Kosovo?
          </h1>
          <h2 className="text-lg text-white font-bold text-center md:text-left w-full md:w-[800px] tracking-wide leading-tight pt-5">
            Kosovo, a small but dynamic country in the heart of the Balkans, is
            growing rapidly into an upcoming center for IT talent in Europe.
            Although it declared its independence in 2008 and is still a young
            republic, Kosovo has quickly developed into a source of innovation
            and technological progress. With a population of which 70% is under
            the age of 35, the country is bursting with energy and ambition to
            make a difference in the world.
          </h2>
          <p className="text-lg text-gray-300 my-8 w-full md:w-[700px] text-center md:text-left tracking-wide leading-relaxed">
            Kosovo has a rich history and a population known for its resilience,
            hospitality and ambition. The majority of Kosovo's population is of
            Albanian origin, a proud culture deeply rooted in traditions of
            cooperation and mutual respect.
          </p>
          {/* <p className="text-lg text-gray-300 my-8 w-full md:w-[700px] text-center md:text-left tracking-wide leading-relaxed">
            At BOTA, we believe in connecting these two worlds: the fast-growing
            IT sector in Kosovo and the need for highly skilled talent in the
            Netherlands. This way we can make an impact for both countries,
            allowing each to grow and develop in their own way.
          </p> */}
        </div>
      </div>
      {/* <h2 className="text-lg font-bold text-white mt-24 max-w-9xl">
          Why work with IT professionals from Kosovo?
        </h2>

        <p className="text-lg text-gray-300 my-8 w-full md:w-[1100px] text-center md:text-left tracking-wide leading-relaxed">
          Kosovo, a small but dynamic country in the heart of the Balkans, is
          growing rapidly into an upcoming center for IT talent in Europe.
          Although it declared its independence in 2008 and is still a young
          republic, Kosovo has quickly developed into a source of innovation and
          technological progress. With a population of which 70% is under the
          age of 35, the country is bursting with energy and ambition to make a
          difference in the world.
        </p>

        <p className="text-lg text-gray-300 my-8 w-full md:w-[1100px] text-center md:text-left tracking-wide leading-relaxed">
          Kosovo has a rich history and a population known for its resilience,
          hospitality and ambition. The majority of Kosovo's population is of
          Albanian origin, a proud culture deeply rooted in traditions of
          cooperation and mutual respect.
        </p>

        <p className="text-lg text-gray-300 my-8 w-full md:w-[1100px] text-center md:text-left tracking-wide leading-relaxed">
          At BOTA, we believe in connecting these two worlds: the fast-growing
          IT sector in Kosovo and the need for highly skilled talent in the
          Netherlands. This way we can make an impact for both countries,
          allowing each to grow and develop in their own way.
        </p> */}

      <div>
        <h2 className="text-lg lg:text-3xl font-bold text-black mt-24 text-center">
          Some benefits of working with Kosovo
        </h2>
        <div className="w-[95%] md:w-[85%] lg:w-[75%] m-auto flex flex-col lg:flex-row items-center lg:items-start justify-between mt-7 flex-wrap">
          <AboutCard
            icon={<FaPeopleLine className="text-xl" />}
            title="A young and ambitious population"
            subtitle={
              "With an average age of 30, Kosovo is one of the youngest countries in Europe. These young, well-educated professionals are technically proficient, fluent in English, and ready to make an impact internationally."
            }
            dataAos={"fade-right"}
          />
          <AboutCard
            icon={<GrTechnology className="text-xl" />}
            title={"Technologically advanced"}
            subtitle={
              "The IT sector is one of the fastest growing industries in Kosovo. Thousands of young people graduate every year in technical fields, often with experience in international projects and the latest technologies. Kosovo has already proven to be a reliable partner for companies worldwide."
            }
            dataAos={"fade-down"}
          />
          <AboutCard
            icon={<SiGitconnected className="text-xl" />}
            title={"Albanian culture of cooperation"}
            subtitle={
              "The people of Kosovo, from Albanian origin, are known for their strong cultural values of cooperation, loyalty and respect. This ensures long-term, reliable working relationships and a pleasant working environment."
            }
            dataAos={"fade-left"}
          />
          <AboutCard
            icon={<MdOutlinePriceChange className="text-xl" />}
            title={"Top performance at a fair price"}
            subtitle={
              "IT professionals from Kosovo deliver high-quality work at rates that are significantly lower than in Western Europe. This means that Dutch companies can find cost-effective solutions without compromising on quality."
            }
            dataAos={"fade-left"}
          />
          <AboutCard
            icon={<RiTimeZoneFill className="text-xl" />}
            title={"Geographical proximity and equal time zone"}
            subtitle={
              "With no time difference between Kosovo and the Netherlands, collaboration is extremely efficient. Real-time communication and hassle-free coordination make collaboration with Kosovo professionals effortless."
            }
            dataAos={"fade-left"}
          />
        </div>
        <div
          className="relative h-[350px] bg-fixed bg-center bg-cover bg-no-repeat mt-20"
          style={{
            backgroundImage: `url(${parallax})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
            <p className="w-[90%] m-auto text-sm lg:text-lg text-gray-300 my-8 lg:w-[60%] text-center">
              Kosovo may be a small player on the world stage, but the country
              has great potential. By partnering with IT professionals from
              Kosovo, you not only gain access to high-quality technical
              expertise, but also help support a young, emerging economy.
            </p>
            <p className="w-[90%] m-auto text-sm lg:text-lg text-gray-300 my-8 lg:w-[40%] text-center">
              At BOTA, we are proud to facilitate this valuable connection
              between Dutch companies and the talent Kosovo has to offer.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ksict;
