import PrimaryButton from "../../primary-button";
import emailjs from "emailjs-com";
import React, { useState } from "react";

const ContactForm = () => {
  const serviceID = process.env.REACT_APP_SERVICE_ID;
  const templateID = process.env.REACT_APP_TEMPLATE_ID;
  const templateID2 = process.env.REACT_APP_TEMPLATE_ID2;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send(serviceID, templateID, {
      from_name: formData.firstName + " " + formData.lastName,
      from_email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
      reply_to: formData.email,
    }, publicKey)
      .then(() => {
        sendConfirmationEmail();
        setIsSubmitted(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          subject: "",
          message: ""
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setError("There was an error sending the message. Please try again.");
      });
  };

  const sendConfirmationEmail = () => {


    emailjs.send(serviceID, templateID2, {
      to_name: formData.firstName + " " + formData.lastName,
      to_email: formData.email,
    }, publicKey)
      .catch((error) => {
        console.error("Error sending confirmation email:", error);
      });
  };

  return (
    <div className="flex justify-center items-center bg-formBg rounded-md">
      <div className="p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-white text-2xl font-bold mb-4">
          Send us a Message
        </h2>
        <p className="text-gray-300 mb-6">
          Feel free to reach out to us with any questions, inquiries, or staffing requirements you may have.
        </p>

        {isSubmitted ? (
          <p className="text-green-500 mb-4">
            Your message has been sent successfully. We'll get back to you soon.
          </p>
        ) : (
          <form className="space-y-4 w-full" onSubmit={sendEmail}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="w-full p-3 rounded-md bg-inputForm text-white placeholder-gray-400"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="w-full p-3 rounded-md bg-inputForm text-white placeholder-gray-400"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="w-full p-3 rounded-md bg-inputForm text-white placeholder-gray-400"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                className="w-full p-3 rounded-md bg-inputForm text-white placeholder-gray-400"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              className="w-full p-3 rounded-md bg-inputForm text-white placeholder-gray-400"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              rows="4"
              className="w-full p-3 rounded-md bg-inputForm text-white placeholder-gray-400"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <button type="submit" className="bg-primary p-3 text-white rounded-md w-full">
              Submit Now
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
