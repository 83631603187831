import React from "react";
import { useNavigate } from "react-router-dom";
import bgImage from "../../assets/our-story.webp";
import newborn from "../../assets/newborn.webp";
import PrimaryButton from "../primary-button";

const Market = () => {
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    navigate("/kosovo-ict-learn-more");
  };
  return (
    <section
      className="w-full h-auto xl:h-[60vh] my-8 pb-10"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
      }}
      id="market"
    >
      <div className="w-[85%] md:w-[75%] lg:w-[65%] xl:w-[65%] h-[100%] m-auto flex flex-col xl:flex-row items-center gap-10 lg:gap-20">
        <img
          alt="team"
          src={newborn}
          className="h-[450px] md:h-[550px] lg:h-[450px] xl:h-[70%] object-contain pt-3 xl:pt-0"
          data-aos={"fade-right"}
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        />
        <div
          className="h-[85%]"
          data-aos={"fade-left"}
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <span className=" text-white rounded-md text-2xl font-bold">
            {" "}
            Why IT professionals from Kosovo?
          </span>
          <p className="text-gray-200 text-sm mt-5 font-semibold ">
            Kosovo is a young and dynamic country in the heart of the Balkans,
            quickly becoming a center for IT talent in Europe. With a population
            of which 70% is under 35, it is bursting with energy and ambition.{" "}
          </p>
          <p className="text-gray-200 text-sm mt-5 font-semibold ">
            IT professionals from Kosovo offer high-quality knowledge, strong
            motivation at competitive rates. The new generation of IT
            professionals combines technical expertise with a fresh perspective
            on innovation and problem solving.
          </p>
          <p className="text-gray-200 text-sm mt-5 font-semibold ">
            At BOTA, we connect this IT talent with the needs of Dutch
            companies. Our in-depth knowledge of both markets ensures a seamless
            collaboration.
          </p>
          <br />
          <p className="text-gray-200 text-sm font-semibold  ">
            By choosing IT professionals from Kosovo, you not only invest in top
            quality, but also in the future of a young economy.
          </p>
          <p className="text-gray-200 text-sm font-semibold pb-16">
            Curious how we can empower your business? Find out how BOTA help
            your business with the best IT talent from Kosovo!
          </p>
          <PrimaryButton text={"Learn more"} onClick={handleLearnMoreClick} />
        </div>
      </div>
    </section>
  );
};

export default Market;
