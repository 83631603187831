import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";

const CareersComponent = () => {

  const serviceID = process.env.REACT_APP_SERVICE_ID;
  const templateID = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  const templateID2 = process.env.REACT_APP_TEMPLATE_ID2;

  const [fileName, setFileName] = useState("No file chosen");
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    phone: "",
    resume: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFormData({ ...formData, resume: file });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      phone: formData.phone,
      subject: "Job Application",
      resume: `${fileName}`,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setSubmitted(true);
        sendConfirmationEmail();
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setError("There was an error sending your application. Please try again.");
      });
  };

  const sendConfirmationEmail = () => {
    const templateParams = {
      to_name: formData.from_name,
      to_email: formData.from_email,
      subject: "Confirmation of Your Job Application",
    };

    emailjs.send(serviceID, templateID2, templateParams, publicKey)
      .then((response) => {
        console.log("Confirmation email sent!", response.status, response.text);
      })
      .catch((err) => {
        console.error("Error sending confirmation email:", err);
      });
  };

  return (
    <div className="min-h-screen bg-[#001431] flex flex-col items-center justify-start p-8 space-y-8">
      <h2 className="text-lg font-bold text-white mt-24 max-w-7xl">
        While we currently do not have any open positions at BOTA, we are always looking for passionate IT professionals who share our mission and vision.
        If you are excited to join our team, we kindly invite you to send us your resume. Tell us about your experience and what you are looking for in a new opportunity.
        We will save your details and contact you as soon as new opportunities become available.
      </h2>
      <div className=" w-full max-w-3xl p-6 rounded-lg shadow-md text-center">
      </div>

      {!submitted ? (
        <div className="w-full max-w-lg bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-center text-gray-800 mb-4">
            Job Application Form
          </h3>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-700 font-medium mb-1">
                First/Last Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-1">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your phone number"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-1">
                Resume <span className="text-red-500">*</span>
              </label>
              <label
                className="cursor-pointer w-full p-2 border border-gray-300 rounded-md text-gray-600 flex items-center justify-between"
                htmlFor="resume"
              >
                <span>{fileName}</span>
                <span className="text-gray-700">Choose File</span>
              </label>
              <input
                id="resume"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                required
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-primary text-white font-bold rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="w-full max-w-lg bg-white p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold text-gray-800">
            Thank you for applying.
          </h3>
        </div>
      )}
    </div>
  );
};

export default CareersComponent;
