import React from "react";

const ContactCard = ({ icon, title, contact }) => {
  return (
    <div className="w-[90%] p-4 bg-white rounded-lg flex items-center gap-5 mt-5 ">
      <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full bg-red-100">
        {icon}
      </div>
      <div>
        <p className="text-gray-400 text-sm lg:text-lg">{title}</p>
        <h1 className="text-lg text-black font-bold lg:text-2xl">{contact}</h1>
      </div>
    </div>
  );
};

export default ContactCard;
