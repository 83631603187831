import React from "react";
import ContactCard from "./contact-card";
import { FaPhoneAlt, FaInstagram, FaLinkedin } from "react-icons/fa";

import { IoMdMail } from "react-icons/io";
import ContactForm from "./contact-form";

const Footer = () => {
  return (
    <section id="contact" className="w-full bg-headerBg h-auto py-[50px] mt-6">
      <div className="w-[95%] lg:w-[80%] xl:w-[75%] h-[100%] m-auto flex flex-col lg:flex-row gap-9 items-center">
        <div className="w-[80%] lg:w-1/2">
          <h1 className="text-primary font-semibold text-3xl">
            Get in Touch Let's Start the Conversation
          </h1>
          <p className="text-gray-600 text-sm lg:text-lg  mt-5">
            At BOTA, we are here to assist you with all your inquiries and needs
            related to IT talent and consultancy. Whether you want to learn more
            about our services, discuss a project, or have specific questions,
            our team is ready to help.
          </p>
          <p className="text-gray-600 text-sm lg:text-lg  mt-5">
            Please fill out the contact form, and we will ensure to get back to
            you as soon as possible.
          </p>
          <p className="text-gray-600 text-sm lg:text-lg  mt-5">
            We appreciate your interest and look forward to collaborating with
            you on solutions that will help your business thrive.
          </p>
          <ContactCard
            icon={<FaPhoneAlt className="text-2xl text-primary" />}
            title={"Gives us a Call"}
            contact={"+31 6 21694048"}
          />
          <ContactCard
            icon={<IoMdMail className="text-2xl text-primary" />}
            title={" Mail"}
            contact={"info@bota-consultancy.com"}
          />
          <div className="flex gap-4 mt-5">
            <a
              href="https://www.linkedin.com/company/botaconsultancyllc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-3xl text-inputForm transition-colors" />
            </a>
            <a
              href="https://www.instagram.com/bota.consultancy/?igsh=MWZ4ZHNsZnhibTY5Zw%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-3xl text-primary transition-colors" />
            </a>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default Footer;
