import React from "react";
import AboutCard from "./about-card";
import { ImPower } from "react-icons/im";
import { MdOutlineEqualizer } from "react-icons/md";
import { DiScala } from "react-icons/di";

const AboutSection = () => {
  return (
    <section
      className="w-[90%] lg:w-[75%] mx-auto my-[50px] h-auto xl:h-[60vh]"
      id="about"
    >
      <div className="flex items-center justify-center flex-col">
        <h1 className="mt-7 text-2xl lg:text-4xl font-semibold">Why BOTA?</h1>
      </div>
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between mt-7 flex-wrap">
        <AboutCard
          icon={<ImPower className="text-xl" />}
          title="Direct access to specialized IT professionals"
          subtitle={
            "BOTA connects your company with IT professionals that perfectly fit your needs. No long recruitment processes, but immediate access to experts with the latest technical knowledge. Ready to join your IT needs from day one."
          }
          dataAos={"fade-right"}
        />
        <AboutCard
          icon={<MdOutlineEqualizer className="text-xl" />}
          title={"Flexible and fast scaling"}
          subtitle={
            "With BOTA, you scale up your IT team exactly when needed. Whether it's temporary support or structural expansion, we deliver the right people quickly so your business can continue to grow without delay. "
          }
          dataAos={"fade-down"}
        />
        <AboutCard
          icon={<DiScala className="text-xl" />}
          title={"Cost-effective without compromising on quality"}
          subtitle={
            "Save time and money with BOTA's flexible solutions. This will allow your business to refocus on core activities and growth. You benefit from high-quality IT expertise without the expensive costs we see in the Dutch job market today. We provide you with exactly what you need, when you need it."
          }
          dataAos={"fade-left"}
        />
      </div>
    </section>
  );
};

export default AboutSection;
