import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as ScrollLink, scroller } from "react-scroll";
import logo from "../../assets/bota-logo.webp";
import PrimaryButton from "../primary-button";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigateToVacancies = () => {
    navigate("/vacancies");
  };

  const handleSidenavLinkOnClick = (section) => {
    const pathName = location.pathname;

    if (pathName !== "/") {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(section, {
          smooth: true,
          duration: 500,
          offset: -70,
        });
      }, 100);
    }
  };

  const shouldHighlightActiveLinks =
    location.pathname !== "/kosovo-ict-learn-more";

  return (
    <div
      className={`fixed p-4 px-7 bg-headerBg transition-all duration-300 flex items-center justify-between z-50 ${
        isScrolled
          ? "top-0 w-full left-0 md:w-[100%] md:left-1/2 md:transform md:-translate-x-1/2 md:rounded-none"
          : "top-0 md:top-4 w-full left-0 md:w-[95%] lg:w-[90%] xl:w-[75%] md:left-1/2 md:transform md:-translate-x-1/2 md:rounded-md"
      }`}
    >
      <img
        alt="Logo"
        src={logo}
        className="h-[55px] object-contain cursor-pointer"
        onClick={() => navigate("/")}
      />

      <ul className="hidden lg:flex items-center gap-[25px] cursor-pointer">
        <ScrollLink
          to="about"
          smooth={true}
          duration={500}
          offset={-70}
          spy={true}
          activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
          className="text-xl hover:text-primary"
          onClick={() => handleSidenavLinkOnClick("about")}
        >
          About
        </ScrollLink>
        <ScrollLink
          to="story"
          smooth={true}
          duration={500}
          offset={-70}
          spy={true}
          activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
          className="text-xl hover:text-primary"
          onClick={() => handleSidenavLinkOnClick("story")}
        >
          Our Story
        </ScrollLink>
        <ScrollLink
          to="services"
          smooth={true}
          duration={500}
          offset={-70}
          spy={true}
          activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
          className="text-xl hover:text-primary"
          onClick={() => handleSidenavLinkOnClick("services")}
        >
          Service
        </ScrollLink>
        <ScrollLink
          to="contact"
          smooth={true}
          duration={500}
          offset={-70}
          spy={true}
          activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
          className="text-xl hover:text-primary"
          onClick={() => handleSidenavLinkOnClick("contact")}
        >
          Contact
        </ScrollLink>

        <h1
          className={`${
            location.pathname.includes("vacancies") && "text-primary"
          } text-xl hover:text-primary cursor-pointer`}
          onClick={handleNavigateToVacancies}
        >
          Vacancies
        </h1>
      </ul>

      <ScrollLink to="contact" smooth={true} duration={500} offset={-70}>
        <PrimaryButton text={"Contact us"} isInHeader={true} />
      </ScrollLink>

      <div className="lg:hidden z-50">
        <button onClick={handleMenuToggle}>
          {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      <div
        className={`lg:hidden fixed top-0 left-0 w-full h-screen bg-headerBg flex flex-col items-center justify-center gap-6 transform transition-transform duration-300 z-40 ${
          isMenuOpen ? "translate-x-0" : "translate-x-[1500px]"
        }`}
      >
        <ul className="flex flex-col items-center gap-[25px] text-xl cursor-pointer">
          <ScrollLink
            to="about"
            smooth={true}
            duration={500}
            offset={-70}
            spy={true}
            activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
            onClick={() => {
              handleSidenavLinkOnClick("about");
              handleMenuToggle();
            }}
          >
            About
          </ScrollLink>
          <ScrollLink
            to="story"
            smooth={true}
            duration={500}
            offset={-70}
            spy={true}
            activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
            onClick={() => {
              handleSidenavLinkOnClick("story");
              handleMenuToggle();
            }}
          >
            Our Story
          </ScrollLink>
          <ScrollLink
            to="services"
            smooth={true}
            duration={500}
            offset={-70}
            spy={true}
            activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
            onClick={() => {
              handleSidenavLinkOnClick("services");
              handleMenuToggle();
            }}
          >
            Service
          </ScrollLink>
          <ScrollLink
            to="contact"
            smooth={true}
            duration={500}
            offset={-70}
            spy={true}
            activeClass={shouldHighlightActiveLinks ? "text-primary" : ""}
            onClick={() => {
              handleSidenavLinkOnClick("contact");
              handleMenuToggle();
            }}
          >
            Contact
          </ScrollLink>

          <h1
            className={`${
              location.pathname.includes("vacanies") && "text-primary"
            } text-xl hover:text-primary cursor-pointer`}
            onClick={() => {
              handleNavigateToVacancies();
              handleMenuToggle();
            }}
          >
            Vacancies
          </h1>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
