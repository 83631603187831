import React from "react";
import OfferCard from "./offer-card";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { GiVirtualMarker } from "react-icons/gi";

const BotaOffer = () => {
  return (
    <section id="services" className="w-full my-5 h-auto xl:h-[65vh]">
      <p className="capitalize text-black font-semibold text-3xl text-center mt-3">
        Our way of working
      </p>
      <div className="mt-10 w-[90%] lg:w-[85%] xl:w-[75%] m-auto flex items-center lg:items-start justify-center lg:justify-between flex-wrap gap-5">
        <OfferCard
          icon={
            <MdOutlineDashboardCustomize className="text-2xl text-primary" />
          }
          title={"We discuss your needs "}
          subtitle={
            "We start with a meeting to understand what your organization is looking for. What are your growth objectives and what IT professionals fit them?"
          }
        />
        <OfferCard
          icon={<RiTeamLine className="text-2xl text-primary" />}
          title={"Selecting the right talent"}
          subtitle={
            "We select the best IT professionals from our network in Kosovo who have the right skills and expertise to make an immediate impact. Together we look at who is the best match for your organization."
          }
        />
        <OfferCard
          icon={<GiVirtualMarker className="text-2xl text-primary" />}
          title={"Collaborate and grow"}
          subtitle={
            "Motivated to get started! The selected professional becomes part of your team, ready to help your business grow faster. We ensure smooth integration and ongoing support."
          }
        />
      </div>
    </section>
  );
};

export default BotaOffer;
